import Service from "../Service";
import Vue from "vue";
const resource = "/lotproductionselectionpalletsend/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationSupply", parameters, {
            params: { requestID: requestID },
        });
    },

    closeLot(obj, requestID) {
        return Service.post("/LotProductionCalibrated/" + "closeLot", obj, {
            params: { requestID: requestID },
        });
    },

};